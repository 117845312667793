.Footer {
  display: flex;
  justify-content: center;
  cursor: inherit;
  width: 100%;
  z-index: 100;
  background-color: transparent;
  position: relative;
  bottom: 0px;
  margin: 0;
}

.Footer .grid-container {
  width: 99%;
  margin: auto;
}

.Footer .grid-item {
  width: 88%;
}

.Footer .footer-item {
  display: inline-block;
  padding: 10px;
  cursor: inherit;
  color: whitesmoke;
  text-decoration: none;
}