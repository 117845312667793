  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    position: sticky;
    top: -260px;
  }
  
  .header .navbar {
    display: flex;
    justify-content: center;
    cursor: inherit;
    width: 100%;
    z-index: 100;
    background-color: transparent;
  }
  
  .navbar .logo {
    display: block;
    cursor: inherit;
    rotate: 0deg;
    translate: 0px;
    text-align: center; /* Add this line to center the text*/
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0); scale:1.05}
    40% {transform: translateY(-18px); scale: 1.05}
    60% {transform: translateY(-14px); scale: 1.05}
  }

  .navbar .logo img:hover {
    animation: bounce 1.5s infinite;
  }
  
  .navbar .menuitem {
    display: inline-block;
    margin: 15px;
    background-color: #9c0707;
    box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.6);
    padding: 10px;
    cursor: inherit;
    rotate: 0deg;
    scale: 1;
    transition: scale 0.2s ease-in-out, 0.1s ease-in-out, translate 0.25s ease-in-out;
    color: whitesmoke;
  }
  
  .navbar .menuitem:hover {
    background-color: #960a0a;
    cursor: url(../../public/images/pieper.png), auto;
    rotate: 5deg;
    translate: 0px 0px;
    scale: 1.25;
  }

  .navbar .menuitem:active {
    translate: 0px 0px;
    scale: 1.35;
  }

  .navbar:focus {
    background-color:yellow;
  }
  
  .navbar a {
    text-decoration: none;
    color: inherit;
    cursor: inherit;
  }

  .sticker {
    position: absolute;
    top: 50px;
    right: 50px;
    rotate: 20deg;
    filter: drop-shadow(8px 8px 3px #222);
  }