.Disclaimer .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: masonry;
    justify-content: center;
    align-items: left; /* Add this line to center the items vertically */
    text-align: left; /* Add this line to center the items horizontally */
    width: 88%;
    place-items: left;
    margin: auto;
    padding: 20px;
    grid-gap: 20px;
  }
  
.Disclaimer .grid-item {
  background-color: #791212dc;
  display: block;
  cursor: inherit;
  border-radius: 5px;
  color: whitesmoke;
  padding: 20px;
}