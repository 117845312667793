html, body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: url(../public/birdcursor.gif), auto;
  animation: cursor .75s linear infinite;
  background-color: #e2c29d;
  background-image: url(../public/images/background3-transformed.jpeg);
  background-size: cover;
  background-attachment: fixed;
  background-position: 0 -0px;
}

@keyframes cursor{
  0% { 
    cursor: url(../public/cursor_frames/frame_0.png), auto;
}
  16% { 
      cursor: url(../public/cursor_frames/frame_1.png), auto;
  }
  33% { 
      cursor: url(../public/cursor_frames/frame_2.png), auto;
  }
  50% { 
      cursor: url(../public/cursor_frames/frame_3.png), auto;
  } 
  66% { 
      cursor: url(../public/cursor_frames/frame_4.png), auto;
  } 
  83% { 
      cursor: url(../public/cursor_frames/frame_5.png), auto;
  } 
  100% {
      cursor: url(../public/cursor_frames/frame_6.png), auto;
  } 
}