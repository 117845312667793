.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  grid-template-rows: masonry;
  justify-content: center;
  align-items: center; /* Add this line to center the items vertically */
  text-align: center; /* Add this line to center the items horizontally */
  width: 88%;
  place-items: center;
  margin: auto;
  padding: 20px;
  grid-gap: 20px;
}

.grid-item {
  background-color: #791212dc;
  display: block;
  cursor: inherit;
  border-radius: 5px;
  color: whitesmoke;
  padding: 20px;
}